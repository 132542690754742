import { useEffect, useState } from "react";

const Profile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [CompanyName, setcompanyname] = useState("");
    useEffect(() => {
        fetch(process.env.REACT_APP_BASEURL + "/members/profile", {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${user.sessionToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setcompanyname(data.company);
            });
    }, [user.sessionToken]);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}>
            <h1 style={{ textAlign: "center" }}>Profile</h1>
            <h3>{CompanyName}</h3>
            <a
                href="/user/changepassword"
                className="changePasswordLink"
                style={{ marginTop: "1.5rem" }}>
                Change Password
            </a>
            <div
                className="card bg-light mb-3"
                style={{ maxWidth: "70%", marginTop: "1.5rem" }}>
                <div className="card-header" align="center">
                    Email
                </div>
                <div className="card-body" align="center">
                    <h5 className="card-title">{user.username}</h5>
                </div>
            </div>
        </div>
    );
};

export default Profile;
