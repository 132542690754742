import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const EventWaitList = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [waitlistitems, setWaitlistItems] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.search.split("=")[1];
  const [error, setError] = useState("");

  useEffect(() => {
    const getWaitlist = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/waitlist?eventid=${localPinID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setWaitlistItems(data);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting waitlist items";
        }
      }
    };

    setLoading(true);
    getWaitlist();
  }, [alertContext, localPinID, navigate]);

  const handleReminder = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}/remind`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (data.success) {
        alertContext.show = true;
        alertContext.msg = "Email(s) sent successfully!";
        alertContext.variant = "primary";
        setError(null);
        navigate(`/events`);
        return;
      } else {
        setError("Error sending emails");
        return;
      }
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      alertContext.msg = err.message || "Error sending reminder";
      navigate("/events");
    }
  };

  return loading ? (
    <Loader />
  ) : waitlistitems?.length === 0 ? (
    <h1>No Pending Email Requests Found</h1>
  ) : (
    <div>
      <h1>Unclaimed ({waitlistitems?.length})</h1>
      <Link to="/events" style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>

      <div style={{ textAlign: "right" }}>
        <button className="btn btn-primary" onClick={handleReminder}>
          Send reminder
        </button>
      </div>

      <div className="row" key="xyz">
        <div className="col-2">
          <b>Email</b>
        </div>
        <div className="col-4">
          <b>Sent on</b>
        </div>
      </div>
      {waitlistitems?.map((waitlistItem) => {
        return (
          <div className="row" key={waitlistItem.id}>
            <div className="col-2" key={"col1" + waitlistItem.id}>
              {waitlistItem.email}
            </div>
            <div className="col-4" key={"col2" + waitlistItem.id}>
              {new Date(waitlistItem.created_at).toLocaleString()}
            </div>
          </div>
        );
      })}

    </div>
  );
};

export default EventWaitList;
