import { useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../App";

const PinsTable = () => {
  const navigate = useNavigate();
  const [allpins, setPins] = useState();

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    getPins();
  }, [alertContext, navigate]);

  const getPins = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/members/pins`, {
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });

      const data = await res.json();
      if (window.location.pathname.includes("active")) {
        setPins(data?.filter((pin) => pin?.active));
      } else {
        setPins(data);
      }
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      if (err.toString()?.includes("Forbidden")) {
        alertContext.msg = "Session expired, please sign in";
        localStorage.removeItem("user");
        navigate("/");
      } else {
        alertContext.msg = "Error getting pins";
      }
    }
  };

  const handleactivate = (e, id) => {
    const { name } = e.target;
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(`${process.env.REACT_APP_BASEURL}/members/pins/${id}/${name}`, {
      headers: {
        Authorization: `Bearer ${user.sessionToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .finally(() => getPins());
  };
  return (
    <>
      <div className="row">
        <div className="col-2">
          <b>Event Name</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b
            title="Number of PiNs allocated for the event."
            data-toggle="tooltip"
          >
            Allocated
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b title="PiNs claimed already" data-toggle="tooltip">
            Claimed
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b title="PiNs sent via email invitation" data-toggle="tooltip">
            Unclaimed
          </b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>PiNs Type</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Status</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Action</b>
        </div>
      </div>

      {allpins &&
        allpins.map((pin) => {
          return (
            <div className="row" key={pin.id}>
              <div
                className="col-2"
                style={{
                  verticalAlign: "top",
                }}
              >
                <a className="detail-link" href={`/events/${pin?.id}`}>
                  {pin.name}
                </a>
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                {pin.maxtokens}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                <a
                  className="detail-link"
                  href={`/tokens/${pin?.id}`}
                >
                  {pin.claimed == 0 ? "" : pin.claimed}
                </a>
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                <a
                  className="detail-link"
                  href={`/eventwaitlist?eventid=${pin?.id}`}
                >
                  {pin.waitlisted == 0 ? "" : pin.waitlisted}
                </a>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
                className="col"
              >
                {pin.evtype === "DIGITAL SOUVENIR"
                  ? "Souvenir PiNs"
                  : "Unknown"}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                {pin.active ? "Active" : "Inactive"}
              </div>
              <div style={{ textAlign: "center" }} className="col">
                {pin.active === 0 ? (
                  <button
                    name="Enable"
                    className="active-navlink"
                    onClick={(e) => handleactivate(e, pin.id)}
                    style={{ border: "none" }}
                    title="Enable this event"
                  >
                    Enable
                  </button>
                ) : (
                  <button
                    name="Disable"
                    onClick={(e) => handleactivate(e, pin.id)}
                    style={{ border: "none" }}
                    title="Disable this event"
                  >
                    Disable
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PinsTable;
