import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const TokenDetail = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [tokens, setTokens] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.pathname.split("/")[2];

  useEffect(() => {
    const getTokens = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/tokens/${localPinID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setTokens(data);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting tokens";
        }
      }
    };

    setLoading(true);
    getTokens();
  }, [alertContext, localPinID, navigate]);

  return loading ? (
    <Loader />
  ) : tokens?.length === 0 ? (
    <h1>No Tokens Found</h1>
  ) : (
    <div>
      <Link onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
        &larr; Back
      </Link>
      <h1>PiNs ({tokens?.length})</h1>

      <div className="row">
        <div class="col-2">
          <b>Token Number</b>
        </div>
        <div class="col-4">
          <b>Attendee Email</b>
        </div>
        <div class="col-2">
          <b>Received On</b>
        </div>
      </div>
      {tokens?.map((token) => {
        return (
          <div className="row" key={token?.tokennumber}>
            <div className="col-2">
              #{token?.tokennumber}
            </div>
            <div className="col-4">
              {token.email}
            </div>
            <div className="col-2">
              {new Date(token.update_at).toLocaleString()}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TokenDetail;
