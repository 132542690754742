import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import Loader from "../Loader";

const WaitList = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [waitlistitems, setWaitlistItems] = useState();
  const [loading, setLoading] = useState();
  const localPinID = window.location.pathname.split("/")[2];

  useEffect(() => {
    const getWaitlist = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/waitlist`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setWaitlistItems(data);
        setLoading(false);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.show = true;
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting waitlist items";
        }
      }
    };

    setLoading(true);
    getWaitlist();
  }, [alertContext, localPinID, navigate]);

  return loading ? (
    <Loader />
  ) : waitlistitems?.length === 0 ? (
    <h1>No Pending Email Requests Found</h1>
  ) : (
    <div>
      <h1>Unclaimed ({waitlistitems?.length})</h1>

      <div className="row">
        <div class="col-2">
          <b>Email</b>
        </div>
        <div class="col-2">
          <b>Event Name</b>
        </div>
        <div class="col-4">
          <b>Sent on</b>
        </div>
      </div>
      {waitlistitems?.map((waitlistItem) => {
        const formatDate = (dateString) => {
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return new Date(dateString).toLocaleDateString(undefined, options);
        };

        return (
          <div className="row">
            <div class="col-2">
              {waitlistItem.email}
            </div>
            <div class="col-2">
              <a className="detail-link" href={`/events/${waitlistItem?.events_id}`}>
                {waitlistItem?.name}
              </a>

            </div>
            <div class="col-4">
              {formatDate(waitlistItem.created_at)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WaitList;
